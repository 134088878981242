import apiInstance from '@apis/index';
import { RegisterFormType } from '@pages/Register/components/RegisterForm';

export enum UserPath {
  Me = '/users/me',
  Register = '/users/sign-up',
  UserProfile = '/users',
  DETAIL = '/users/:id',
  UpdatePassword = '/users/:id/password',
}

export const whoAmI = async () => {
  return apiInstance.get(UserPath.Me);
};

export const register = async (formData: RegisterFormType) => {
  return apiInstance.post(UserPath.Register, { ...formData });
};

export const getUserProfile = async (id: string) => {
  return apiInstance.get(`${UserPath.UserProfile}/${id}`);
};

export const getUserDetail = async (id: string) => {
  return apiInstance.get(UserPath.DETAIL.replace(':id', id));
};

export const updateUserProfile = async (
  id: string,
  formData: RegisterFormType,
) => {
  return apiInstance.patch(UserPath.DETAIL.replace(':id', id), { ...formData });
};

export const updatePassword = async (
  id: string,
  currentPassword: string,
  newPassword: string,
) => {
  return apiInstance.patch(UserPath.UpdatePassword.replace(':id', id), {
    currentPassword,
    newPassword,
  });
};
