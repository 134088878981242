import LogoFake from '@assets/icons/icon_camp-tent.svg';
import { Col, Row, Typography } from 'antd';
import Right from './components/Right';
import './style.less';

const FooterFirstCol = () => {
  return (
    <Row align="middle" justify="start">
      <Row className="full-width" justify="start">
        <Col>
          <img className="footer-logo" src={LogoFake} alt="" />
        </Col>
        <Col flex={1}>
          <Right />
        </Col>
      </Row>
      <Row className="full-width" justify="start">
        <Col flex={1}>
          <Typography.Text className="glc-text text">
            プライバシーポリシー
          </Typography.Text>
          <Typography.Text className="glc-text text">
            Cookie (クッキー）ポリシー
          </Typography.Text>
          <Typography.Text className="glc-text text">
            特定商取引法に基づく表記（例）
          </Typography.Text>
        </Col>
      </Row>
    </Row>
  );
};

export default FooterFirstCol;
