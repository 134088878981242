import ROUTE_PATH from '@constants/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.less';
import UserHolder from '@components/Header/components/UserHolder';

const RightPanel = () => {
  const { t } = useTranslation('Common');

  return (
    <div className="header-right">
      <ul className="header-right-list">
        <li className="header-right-text">
          <a href="/#header-content" style={{ marginBottom: 0 }}>
            ShinYuriCampの魅力
          </a>
        </li>
        <UserHolder />
        <li className="header-right-text">
          <Link to={ROUTE_PATH.CONTACT}>{t('Navigation.Contact')}</Link>
        </li>
      </ul>
    </div>
  );
};

export default RightPanel;
