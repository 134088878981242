import LogoFake from '@assets/icons/icon_camp-tent.svg';
import './style.less';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from '@constants/routes';

const Logo = () => {
  const navigate = useNavigate();

  return (
    <div className="logo" onClick={() => navigate(ROUTE_PATH.HOME)}>
      <img className="logo-icon" src={LogoFake} alt="" />
    </div>
  );
};

export default Logo;
