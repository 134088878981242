import ROUTE_PATH from '@constants/routes';
import { NavigateToNotFound } from '@templates/PolicyRoute/PublicRoute';
import pageLoader from '@utils/pageLoader';
import React, { lazy, ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';

const HomePage = lazy(pageLoader(() => import('@pages/Home')));
const SignInPage = lazy(pageLoader(() => import('@pages/SignIn')));
const RegisterPage = lazy(pageLoader(() => import('@pages/Register')));
const ProfilePage = lazy(pageLoader(() => import('@pages/User/Profile')));
const ProfileDetailPage = lazy(
  pageLoader(() => import('@pages/User/ProfileDetail')),
);
const MyPage = lazy(pageLoader(() => import('@pages/MyPage')));
const ExplorePage = lazy(pageLoader(() => import('@pages/Explore')));
const NotFoundPage = lazy(pageLoader(() => import('@pages/NotFound')));
const MediaPage = lazy(pageLoader(() => import('@pages/Media')));
const QAPage = lazy(pageLoader(() => import('@pages/Q&A')));
const BookingPage = lazy(pageLoader(() => import('@pages/Booking')));
const BookingStatePage = lazy(pageLoader(() => import('@pages/BookingState')));
const InvoicePage = lazy(pageLoader(() => import('@pages/Invoice')));
const InvoiceDetailPage = lazy(
  pageLoader(() => import('@pages/InvoiceDetail')),
);
// const RentalPropertyDetailPage = lazy(
//   pageLoader(() => import('@pages/RentalPropertyDetail')),
// );
const ForgotPasswordPage = lazy(
  pageLoader(() => import('@pages/ForgotPassword')),
);
const ResetPasswordPage = lazy(
  pageLoader(() => import('@pages/ResetPassword')),
);
const MobileMapPage = lazy(pageLoader(() => import('@pages/MobileMap')));
const PaymentCallbackPage = lazy(
  pageLoader(() => import('@pages/PaymentCallback')),
);
const VerifyEmailPage = lazy(pageLoader(() => import('@pages/VerifyEmail')));
const NewsDetailPage = lazy(pageLoader(() => import('@pages/NewsDetail')));
const SettingsPage = lazy(pageLoader(() => import('@pages/Settings')));
const UsageHistoryPage = lazy(pageLoader(() => import('@pages/UsageHistory')));

type RouterExtraProps = {
  isPublic?: boolean;
  suspenseFallback?: ReactNode;
};

type CustomRouterProps = RouteProps & RouterExtraProps;

const ROUTER: CustomRouterProps[] = [
  // Home
  {
    isPublic: true,
    path: ROUTE_PATH.HOME,
    element: React.createElement(HomePage, null, null),
  },
  // Authentication
  {
    isPublic: true,
    path: ROUTE_PATH.SIGN_IN,
    element: React.createElement(SignInPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.REGISTER,
    element: React.createElement(RegisterPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.FORGOT_PASSWORD,
    element: React.createElement(ForgotPasswordPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.RESET_PASSWORD,
    element: React.createElement(ResetPasswordPage, null, null),
  },
  // User
  {
    isPublic: true,
    path: ROUTE_PATH.PROFILE,
    element: React.createElement(ProfilePage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.PROFILE_DETAIL,
    element: React.createElement(ProfileDetailPage, null, null),
  },
  // My page
  {
    isPublic: true,
    path: ROUTE_PATH.MY_PAGE,
    element: React.createElement(MyPage, null, null),
  },
  // Explore
  {
    isPublic: true,
    path: ROUTE_PATH.EXPLORE,
    element: React.createElement(ExplorePage, null, null),
  },
  // Qnas
  {
    isPublic: true,
    path: ROUTE_PATH.QNAS,
    element: React.createElement(QAPage, null, null),
  },
  // Invoice
  {
    isPublic: true,
    path: ROUTE_PATH.INVOICES,
    element: React.createElement(InvoicePage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.INVOICES_DETAIL,
    element: React.createElement(InvoiceDetailPage, null, null),
  },
  // Booking
  {
    isPublic: true,
    path: ROUTE_PATH.BOOKING,
    element: React.createElement(BookingPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.BOOKING_STATE,
    element: React.createElement(BookingStatePage, null, null),
  },
  // Media
  {
    isPublic: true,
    path: ROUTE_PATH.MEDIA,
    element: React.createElement(MediaPage, null, null),
  },
  // News
  {
    isPublic: true,
    path: ROUTE_PATH.NEWS_DETAIL,
    element: React.createElement(NewsDetailPage, null, null),
  },
  // Settings
  {
    isPublic: true,
    path: ROUTE_PATH.SETTINGS,
    element: React.createElement(SettingsPage, null, null),
  },
  // Usage history
  {
    isPublic: true,
    path: ROUTE_PATH.USAGE_HISTORY,
    element: React.createElement(UsageHistoryPage, null, null),
  },
  // Mobile Map WebView
  {
    isPublic: true,
    path: ROUTE_PATH.MOBILE_MAP,
    element: React.createElement(MobileMapPage, null, null),
  },
  // Payment callback
  {
    isPublic: true,
    path: ROUTE_PATH.PAYMENT_CALLBACK,
    element: React.createElement(PaymentCallbackPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.VERIFY_EMAIL,
    element: React.createElement(VerifyEmailPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.NOT_FOUND,
    element: React.createElement(NotFoundPage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.OTHER,
    element: React.createElement(NavigateToNotFound, null, null),
  },
];

export const NOT_DEFAULT_LAYOUT_PATH = [
  ROUTE_PATH.MOBILE_MAP,
  ROUTE_PATH.PAYMENT_CALLBACK,
  ROUTE_PATH.VERIFY_EMAIL,
  ROUTE_PATH.RESET_PASSWORD,
];

export const PAYMENT_SUCCESS_CALLBACK_MSG =
  'PaymentSuccess->ClosePaymentWindow';

export default ROUTER;
