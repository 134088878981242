import { RPCategory } from 'interface/bookings';

export const BookingRPTypes = [
  { id: RPCategory.Camp, color: '#3A6572', title: 'Booking.RPType.Camp' },
  { id: RPCategory.BBQ, color: '#A07454', title: 'Booking.RPType.BBQ' },
  { id: RPCategory.Game, color: '#4C8444', title: 'Booking.RPType.Game' },
  { id: RPCategory.Bike, color: '#D4644C', title: 'Booking.RPType.Bike' },
  {
    id: RPCategory.RentalVacation,
    color: '#AC9484',
    title: 'Booking.RPType.Vacation',
  },
];
