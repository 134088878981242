import { Col, Row } from 'antd';
import { FC } from 'react';
import FooterFirstCol from '@components/Footer/components/FooterFirstCol';
import FooterSecondCol from '@components/Footer/components/FooterSecondCol';
import FooterThirdCol from '@components/Footer/components/FooterThirdCol';
import './style.less';

const Footer: FC = () => {
  return (
    <Row justify="space-between" align="top" className="full-width">
      <Col lg={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
        <FooterFirstCol />
      </Col>
      <Col lg={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
        <FooterSecondCol />
      </Col>
      <Col
        lg={{ span: 8 }}
        sm={{ span: 8 }}
        xs={{ span: 24 }}
        className="third-col"
      >
        <FooterThirdCol />
      </Col>
    </Row>
  );
};

export default Footer;
