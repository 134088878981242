import { IData } from 'interface/common';
import { IInvoice } from 'interface/invoice';
import { Payment } from 'interface/payments';
import { IRentalProperty } from 'interface/RentalProperty';

export interface IBookingPayload {
  rentalProperty: string;
  quantity: number;
  startAt: Date;
  endAt: Date;
}

export interface IBookingData {
  rentalProperty: IRentalProperty;
  quantity: number;
  startAt: Date;
  endAt: Date;
}

export interface IListBookingQuery {
  workspace: string;
  ids: string[];
  createdAfter: string;
  createdBefore: string;
  rentalPropertyIds: string[];
  bookingStartAt: string;
  bookingEndAt: string;
  unlimited: boolean;
  order: string;
  page: number;
  take: number;
  orderBy: string;
}

export enum BookingState {
  CashPayment = 'CashPayment',
  OnlinePayment = 'OnlinePayment',
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  Complete = 'Complete',
  Canceled = 'Canceled',
}

export interface IBooking extends IData {
  currentBookingPrice: number;
  startAt: Date;
  endAt: Date;
  state: BookingState;
  rentalProperty: IRentalProperty;
  payment: Payment;
  invoice: IInvoice;
  quantity: number;
}

export enum RPCategory {
  Camp = 'Camp',
  BBQ = 'BBQ',
  Game = 'Game',
  Bike = 'Bike',
  RentalVacation = 'Vacation Rental',
}

export interface IBookingRPType {
  id: RPCategory;
  color: string;
  title: string;
}
