import { whoAmI } from '@apis/users';
import ChatbotInjection from '@components/ChatbotInjection';
import Footer from '@components/Footer';
import Header from '@components/Header';
import { SupportedLocale } from '@constants/locales';
import ROUTER, { NOT_DEFAULT_LAYOUT_PATH } from '@constants/routers';
import '@helpers/i18next';
import { useAppDispatch } from '@redux/hooks';
import { login, logout } from '@slices/authorization/authorizationSlice';
import PolicyRoute from '@templates/PolicyRoute';
import { getAccessToken } from '@utils/auth';
import { ConfigProvider, Layout } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.less';

ConfigProvider.config({
  prefixCls: 'glc',
});
const shortWeekDays = ['日', '月', '火', '水', '木', '金', '土'];
const shortMonths = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
];
const jaLocale =
  !jaJP.DatePicker || !jaJP.Calendar
    ? jaJP
    : {
        ...jaJP,
        Calendar: {
          ...jaJP.Calendar,
          lang: {
            ...jaJP.Calendar.lang,
            shortWeekDays,
          },
        },
        DatePicker: {
          ...jaJP.DatePicker,
          lang: {
            ...jaJP.DatePicker.lang,
            shortWeekDays,
            shortMonths,
          },
        },
      };

const App = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation('Common');
  const locale = useMemo(() => {
    switch (i18n.language) {
      case SupportedLocale['ja-JP']:
        return jaLocale;
      default:
        return enUS;
    }
  }, [i18n.language]);

  useEffect(() => {
    (async () => {
      if (getAccessToken() == null) return;
      const resAuth = await whoAmI();
      if (resAuth.status === 200 && resAuth.data) {
        dispatch(login(resAuth.data));
      } else {
        dispatch(logout());
      }
    })();
  }, [dispatch]);

  return (
    <ConfigProvider prefixCls="glc" locale={locale}>
      {NOT_DEFAULT_LAYOUT_PATH.includes(pathname) ? (
        <Routes>
          {ROUTER.map(({ path, isPublic, element, suspenseFallback }) => (
            <Route
              path={path}
              key={path}
              element={
                <PolicyRoute {...{ isPublic, suspenseFallback }}>
                  {element}
                </PolicyRoute>
              }
            />
          ))}
        </Routes>
      ) : (
        <Layout id="root-layout">
          <Layout.Header id="layout-header">
            <Header />
          </Layout.Header>
          <Layout.Content id="layout-content">
            <Routes>
              {ROUTER.map(({ path, isPublic, element, suspenseFallback }) => (
                <Route
                  path={path}
                  key={path}
                  element={
                    <PolicyRoute {...{ isPublic, suspenseFallback }}>
                      {element}
                    </PolicyRoute>
                  }
                />
              ))}
            </Routes>
            <ChatbotInjection />
          </Layout.Content>
          <Layout.Footer id="layout-footer">
            <Footer />
          </Layout.Footer>
        </Layout>
      )}
    </ConfigProvider>
  );
};

export default App;
