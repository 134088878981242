import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authorizationReducer from './slices/authorization/authorizationSlice';
import bookingReducer from './slices/booking/bookingSlice';
import qnasReducer from './slices/qnas/qnasSlice';

export const store = configureStore({
  reducer: {
    authorization: authorizationReducer,
    qnas: qnasReducer,
    booking: bookingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
