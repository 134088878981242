import { ChatbotConfig, getChatbotConfig } from '@apis/chatbot';
import { extractDomain } from '@utils/url';
import { useCallback, useEffect, useState } from 'react';

const ChatbotInjection = () => {
  const [botConfig, setBotConfig] = useState<ChatbotConfig>();

  const inject = useCallback((botUrl: string) => {
    const iframe$ = document.createElement('iframe');
    // @ts-ignore:next-line
    window.AIME_FLUENT_DEFAULT_OPEN = false;
    window.addEventListener(
      'message',
      (e) => {
        if (!e) {
          return;
        }
        if (e.data === '_aime_chat_open_') {
          iframe$.style.maxHeight = '525px';
          iframe$.style.maxWidth = '100%';
          iframe$.style.width = '390px';
        } else if (e.data === '_aime_chat_close_') {
          iframe$.style.maxHeight = '136px';
          iframe$.style.width = '159px';
        } else if (e.data === '_aime_chat_shut_down_') {
          iframe$.style.maxHeight = '60px';
          iframe$.style.width = '61px';
        } else if (e.data === '_aime_chat_ready_') {
          // @ts-ignore:next-line
          if (window.AIME_FLUENT_DEFAULT_OPEN) {
            iframe$.contentWindow?.postMessage('_expand_', '*');
          }
        }
      },
      false,
    );

    const div$ = document.createElement('div');
    div$.setAttribute('id', 'JKChatView_place_holder');
    iframe$.setAttribute('id', 'aime_fluent_chat');
    iframe$.setAttribute(
      'style',
      'z-index:100; max-height: 136px; width: 159px; position: fixed; right: 10px; bottom: 10px; height: 100%; border: none; background: none;',
    );
    document.body.appendChild(div$);
    div$.appendChild(iframe$);
    iframe$.src = botUrl;

    return () => {
      document.body.removeChild(div$);
    };
  }, []);

  useEffect(() => {
    getChatbotConfig().then((config) => {
      setBotConfig(config);
    });
  }, []);

  useEffect(() => {
    if (!botConfig) {
      return () => {};
    }
    return inject(
      `${extractDomain(botConfig.botUrl)}/bot/${
        botConfig.botId
      }/render_bot_v8?lang=ja&bot_lang=ja`,
    );
  }, [inject, botConfig]);

  return null;
};

export default ChatbotInjection;
