import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQnAs } from '@hooks/Q&A/useQA';

interface QnAsData {
  qnasList: IQnAs[];
}

const initialState = {
  qnasList: [],
} as QnAsData;

const qnasSlice = createSlice({
  name: 'qnas',
  initialState,
  reducers: {
    setQAList: (state, action: PayloadAction<IQnAs[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.qnasList = action.payload;
    },
  },
});

export const { setQAList } = qnasSlice.actions;
export default qnasSlice.reducer;
