import PrivateRoute from '@templates/PolicyRoute/PrivateRoute';
import PublicRoute from '@templates/PolicyRoute/PublicRoute';
import SuspenseWrapper from '@templates/PolicyRoute/SuspenseWrapper';
import { ComponentType, FC, LazyExoticComponent, ReactNode } from 'react';

export type PolicyRouteProps = {
  isPublic?: boolean;
  children?: ReactNode | LazyExoticComponent<ComponentType<any>>;
  suspenseFallback?: ReactNode;
};

const PolicyRoute: FC<PolicyRouteProps> = ({
  isPublic,
  children,
  suspenseFallback,
}) => {
  if (isPublic)
    return (
      <SuspenseWrapper suspenseFallback={suspenseFallback}>
        <PublicRoute>{children as ReactNode}</PublicRoute>
      </SuspenseWrapper>
    );

  return (
    <SuspenseWrapper suspenseFallback={suspenseFallback}>
      <PrivateRoute>{children as ReactNode}</PrivateRoute>;
    </SuspenseWrapper>
  );
};

PolicyRoute.defaultProps = {
  isPublic: true,
  children: null,
  suspenseFallback: null,
};

export default PolicyRoute;
