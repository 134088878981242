import ROUTE_PATH from '@constants/routes';
import { logout as logoutAction } from '@slices/authorization/authorizationSlice';
import { clearAuthorizationToken } from '@utils/auth';
import { Dropdown, MenuProps, Typography } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import './style.less';

const UserHolder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Common');
  const navigate = useNavigate();
  const authorization = useAppSelector((state) => state.authorization);

  const logout = useCallback(() => {
    clearAuthorizationToken();
    dispatch(logoutAction());
    navigate(ROUTE_PATH.SIGN_IN);
  }, [dispatch, navigate]);

  const dropdownItems = useMemo<MenuProps['items']>(
    () => [
      {
        key: 'profile',
        label: <Typography.Text>{t('MyPage')}</Typography.Text>,
        onClick: () => navigate(ROUTE_PATH.MY_PAGE),
      },
      {
        key: 'profile-detail',
        label: <Typography.Text>{t('UpdateProfile')}</Typography.Text>,
        onClick: () => navigate(ROUTE_PATH.PROFILE_DETAIL),
      },
      {
        key: 'logout',
        label: <Typography.Text>{t('Logout')}</Typography.Text>,
        onClick: logout,
      },
    ],
    [navigate, t, logout],
  );

  if (authorization)
    return (
      <Dropdown
        overlayStyle={{ minWidth: '160px' }}
        menu={{ items: dropdownItems }}
        placement="bottomRight"
      >
        <li className="header-right-text">
          {`${authorization.firstName} ${authorization.lastName}`}
        </li>
      </Dropdown>
    );

  return (
    <li className="header-right-text">
      <Link to={ROUTE_PATH.SIGN_IN}>{t('Navigation.Login')}</Link>
    </li>
  );
};

export default UserHolder;
