import { Col, Row, Typography } from 'antd';
import './style.less';

const FooterSecondCol = () => {
  return (
    <Row align="middle" justify={{ lg: 'center', sm: 'end', xs: 'end' }}>
      <Col>
        <a href="https://fb.com" target="_blank" rel="noreferrer">
          <Typography.Text className="glc-text text">Facebook</Typography.Text>
        </a>
        <a href="https://instagram.com" target="_blank" rel="noreferrer">
          <Typography.Text className="glc-text text">Instagram</Typography.Text>
        </a>
      </Col>
    </Row>
  );
};

export default FooterSecondCol;
