const ROUTE_PATH = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  PROFILE_DETAIL: '/profile-detail',
  MY_PAGE: '/my-page',
  CONTACT: '/contact',
  EXPLORE: '/explore',
  EXPLORE_DETAIL: '/explore/:id',
  PAYMENT: '/payment',
  BOOKING: '/booking',
  BOOKING_STATE: '/booking-state',
  INVOICES: '/invoices',
  INVOICES_DETAIL: '/invoices/:id',
  PAYMENT_CALLBACK: '/payments/callback',
  MEDIA: '/media',
  QNAS: '/qna',
  NEWS: '/news',
  NEWS_DETAIL: '/news/:id',
  MOBILE_MAP: '/mobile-map',
  VERIFY_EMAIL: '/verify-email',
  SETTINGS: '/settings',
  USAGE_HISTORY: '/usage-history',
  NOT_FOUND: '404',
  OTHER: '*',
};

export default ROUTE_PATH;
