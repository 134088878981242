type AppConfig = {
  apiEndpoint: string;
  dynamicLink: {
    signInPage: string;
  };
  interactableMap: boolean;
};

declare global {
  interface Window {
    appConfigs: AppConfig;
  }
}

const { appConfigs } = window;

export const API_ENDPOINT = appConfigs.apiEndpoint;

export default appConfigs;
