import ROUTE_PATH from '@constants/routes';
import { FC, ReactElement } from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';

export const NavigateToNotFound = () => {
  const location = useLocation();
  return (
    <Navigate to={ROUTE_PATH.NOT_FOUND} state={{ lastLocation: location }} />
  );
};

const PublicRoute: FC<RouteProps> = ({ children }) => {
  return children as ReactElement;
};

export default PublicRoute;
