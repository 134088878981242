import { Row, Col, Typography, Button } from 'antd';
import './style.less';
import { useTranslation } from 'react-i18next';
import { UpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from '@constants/routes';

const FooterThirdCol = () => {
  const { t } = useTranslation('Common');
  const navigate = useNavigate();

  return (
    <Row align="middle" justify="end">
      <Col>
        <Button
          type="default"
          className="footer-button-booking"
          onClick={() => navigate(ROUTE_PATH.BOOKING)}
        >
          {t('Button.Booking')}
        </Button>
        <Typography.Text
          className="glc-text text"
          style={{ marginTop: '18px', cursor: 'pointer' }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {t('Button.Back')}
          <UpOutlined style={{ marginLeft: '8px' }} />
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default FooterThirdCol;
