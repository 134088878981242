import { SUPPORTED_LOCALES, SupportedLocale } from '@constants/locales';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import I18NextChainedBackend from 'i18next-chained-backend';
import I18NextHttpBackend from 'i18next-http-backend';
import I18NextLocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

// @ts-ignore
i18n
  .use(I18NextChainedBackend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LOCALES.map(({ value }) => value),
    // lng: localStorage.getItem('i18nextLng')!!
    //   ? undefined
    //   : SupportedLocale['ja-JP'],
    lng: SupportedLocale['ja-JP'],
    fallbackLng: SupportedLocale['ja-JP'],
    defaultNS: 'Common',
    fallbackNS: 'Common',
    backend: {
      backends: [I18NextLocalStorageBackend, I18NextHttpBackend],
      backendOptions: [
        {
          expirationTime: 1 * 24 * 60 * 60 * 1000, // 7 days
          defaultVersion: '0.0.18',
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export default i18n;
