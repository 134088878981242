/* eslint-disable no-param-reassign */
import { BookingRPTypes } from '@constants/bookings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBookingRPType } from 'interface/bookings';
import { IInvoice } from 'interface/invoice';
import { IRentalProperty } from 'interface/RentalProperty';
import type { RootState } from '../../store';

export enum BookingPhase {
  SelectRP = 'Booking.Phase.SelectRP',
  SelectBookingDetail = 'Booking.Phase.SelectBookingDetail',
  CheckSummary = 'Booking.Phase.CheckTotal',
  Checkout = 'Booking.Phase.Checkout',
}

export enum PaymentMethod {
  Cash = 'Cash',
  Online = 'Online',
}

interface IBookingExtra {
  rentalProperty: IRentalProperty;
  quantity: number;
}
export interface IBookingDetail {
  rentalProperty: IRentalProperty;
  quantity: number;
  startAt: string;
  endAt: string;
  extras: IBookingExtra[];
}

interface IBookingState {
  phase: BookingPhase;
  selectedRPBookingType: IBookingRPType;
  selectedRP: IRentalProperty | null;
  ready: boolean;
  selectedDate: string;
  bookings: IBookingDetail[];
  adults: number;
  children: number;
  paymentMethod: PaymentMethod;
  invoice: IInvoice | null;
}

interface IUpdateBookingPayload {
  index: number;
  updatePayload: Partial<IBookingDetail>;
}

interface IUpdateBookingExtraPayload {
  index: number;
  updatePayload: IBookingExtra;
}

const initialState: IBookingState = {
  phase: BookingPhase.SelectRP,
  selectedRPBookingType: BookingRPTypes[0],
  selectedRP: null,
  ready: false,
  selectedDate: new Date().toISOString(),
  bookings: [],
  adults: 1,
  children: 0,
  paymentMethod: PaymentMethod.Cash,
  invoice: null,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    changeRPBookingType: (state, action: PayloadAction<IBookingRPType>) => {
      state.selectedRPBookingType = action.payload;
      state.ready = false;
      state.selectedRP = null;
      return state;
    },
    changRP: (state, action: PayloadAction<IRentalProperty | null>) => {
      state.selectedRP = action.payload;
      state.ready = true;
      return state;
    },
    selectBookingDate: (state, action: PayloadAction<string>) => {
      state.phase = BookingPhase.SelectBookingDetail;
      state.selectedDate = action.payload;
      return state;
    },
    changeAdults: (state, action: PayloadAction<number>) => {
      state.adults = action.payload < 1 ? 1 : action.payload;
      return state;
    },
    changeChildren: (state, action: PayloadAction<number>) => {
      state.children = action.payload < 0 ? 0 : action.payload;
      return state;
    },
    addBooking: (state, action: PayloadAction<IBookingDetail>) => {
      state.bookings = [...state.bookings, action.payload];
      state.phase = BookingPhase.SelectBookingDetail;
      return state;
    },
    removeBooking: (state, action: PayloadAction<number>) => {
      state.bookings.splice(action.payload, 1);
      return state;
    },
    updateBooking: (state, action: PayloadAction<IUpdateBookingPayload>) => {
      const { index, updatePayload } = action.payload;
      const { bookings } = state;
      bookings[index] = Object.assign(bookings[index], updatePayload);
      state.phase = BookingPhase.SelectBookingDetail;
      return state;
    },
    updateExtraBooking: (
      state,
      action: PayloadAction<IUpdateBookingExtraPayload>,
    ) => {
      const { index, updatePayload } = action.payload;
      const booking = state.bookings[index];
      if (updatePayload.quantity !== 0) {
        const current = booking.extras.find(
          ({ rentalProperty }) =>
            rentalProperty.id === updatePayload.rentalProperty.id,
        );
        if (!current) {
          booking.extras.push(updatePayload);
        } else {
          current.quantity = updatePayload.quantity;
        }
      } else {
        const current = booking.extras.findIndex(
          ({ rentalProperty }) =>
            rentalProperty.id === updatePayload.rentalProperty.id,
        );
        if (current !== -1) {
          booking.extras.splice(current, 1);
        }
      }
      return state;
    },
    updateBookingPhase: (state, action: PayloadAction<BookingPhase>) => {
      state.phase = action.payload;
      return state;
    },
    updatePaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
      return state;
    },
    createdInvoice: (state, action: PayloadAction<IInvoice>) => {
      state.invoice = action.payload;
      return state;
    },
    completePayment: (state) => {
      state.phase = BookingPhase.Checkout;
      return state;
    },
    resetBooking: () => {
      return initialState;
    },
  },
});

export const {
  changeRPBookingType,
  changRP,
  selectBookingDate,
  addBooking,
  removeBooking,
  updateBooking,
  updateExtraBooking,
  changeAdults,
  changeChildren,
  updateBookingPhase,
  updatePaymentMethod,
  createdInvoice,
  completePayment,
  resetBooking,
} = bookingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
