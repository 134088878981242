/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { API_ENDPOINT } from '@constants/configs';
import i18n from '@helpers/i18next';
import {
  AuthorizationResponse,
  clearAuthorizationToken,
  getAccessToken,
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '@utils/auth';
import { notification } from 'antd';
import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import jwtDecode from 'jwt-decode';

export type DecodedJWT = {
  exp: number;
  iat: number;
} & {
  [t: string]: string;
};

const reauthorize = async (): Promise<AuthorizationResponse | null> => {
  try {
    const refreshToken = getRefreshToken();
    if (!refreshToken)
      throw new Error('No refresh token! Need to login again.');
    const instance = Axios.create({
      baseURL: API_ENDPOINT,
    });
    const res = await instance.get('/auth/refresh', {
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    });
    if (res.status !== 200)
      throw new Error(
        'Authorization with refresh token failed! Need to login again.',
      );
    return res.data;
  } catch (err: any) {
    if (Axios.isAxiosError(err))
      console.log(
        '[Auth] Authorization with refresh token failed! Need to login again.',
      );
    else {
      console.log(`[Auth] ${err?.message}`);
      notification.warn({
        message: i18n.t('ExpiredSession'),
        onClose: () => window.location.replace('/sign-in'),
      });
    }
    return null;
  }
};

const request = async (
  axiosFunc: AxiosFunc,
  ...args:
    | [string, any, AxiosRequestConfig | undefined]
    | [string, AxiosRequestConfig | undefined]
): Promise<AxiosResponse> => {
  try {
    // @ts-ignore
    return (await axiosFunc(...args)) as AxiosResponse;
  } catch (error: unknown) {
    let res = {};
    if (Axios.isAxiosError(error)) {
      // Access to config, request, and response
      res = error.response ?? ({} as AxiosResponse);
    }
    return res as AxiosResponse;
  }
};

type AxiosFunc =
  | typeof Axios.get
  | typeof Axios.post
  | typeof Axios.patch
  | typeof Axios.put
  | typeof Axios.delete;

class CustomAxiosInstance {
  instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = Axios.create({
      baseURL,
    });

    this.instance.interceptors.request.use(
      async (config) => {
        try {
          const accessToken = getAccessToken();
          const refreshToken = getRefreshToken();
          if (!accessToken && !refreshToken) return config;
          if (
            !accessToken ||
            (jwtDecode(accessToken) as DecodedJWT).exp <= Date.now() / 1000
          ) {
            console.log(
              '[Auth] Access token expired! Reauthorizing with refresh token.',
            );
            const result = await reauthorize();
            if (result) {
              console.log(
                '[Auth] Reauthorization success. Retrying last request',
              );
              saveAccessToken(result.accessToken);
              saveRefreshToken(result.refreshToken);
            } else {
              clearAuthorizationToken();
            }
          }

          config.headers = {
            Authorization: `Bearer ${getAccessToken()}`,
            Accept: 'application/json',
          } as unknown as AxiosRequestHeaders;
          return config;
        } catch (err) {
          return config;
        }
      },
      (error) => {
        Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === '/auth/login') return Promise.reject(error);
        if (error.response.status === 401 && !originalRequest._retry) {
          console.log(
            '[Auth] Access token expired! Reauthorizing with refresh token.',
          );
          originalRequest._retry = true;
          const result = await reauthorize();
          if (result) {
            console.log(
              '[Auth] Reauthorization success. Retrying last request',
            );
            saveAccessToken(result.accessToken);
            saveRefreshToken(result.refreshToken);
            this.instance.defaults.headers.common.authorization = `Bearer ${result.accessToken}`;
            return this.instance(originalRequest);
          }
          clearAuthorizationToken();
        }
        return Promise.reject(error);
      },
    );
  }

  get(path: string, configs?: AxiosRequestConfig): Promise<AxiosResponse> {
    return request(this.instance.get, path, configs);
  }

  post(
    path: string,
    data: any,
    configs?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return request(this.instance.post, path, data, configs);
  }

  put(
    path: string,
    data: any,
    configs?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return request(this.instance.put, path, data, configs);
  }

  patch(
    path: string,
    data: any,
    configs?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return request(this.instance.patch, path, data, configs);
  }

  delete(path: string, configs?: AxiosRequestConfig): Promise<AxiosResponse> {
    return request(this.instance.delete, path, configs);
  }
}

export default CustomAxiosInstance;
