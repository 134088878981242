import ROUTE_PATH from '@constants/routes';
import { FC } from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export const NavigateToHome = () => {
  return <Navigate to={ROUTE_PATH.SIGN_IN} />;
};

const PrivateRoute: FC<RouteProps> = ({ path, children }) => {
  const authorization = useAppSelector((state) => state.authorization);

  if (authorization == null) return <NavigateToHome />;

  return <Route path={path}>{children}</Route>;
};

export default PrivateRoute;
