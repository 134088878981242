import RightPanel from '@components/Header/components/RightPanel';
import { Col, Row } from 'antd';
import Logo from '@components/Header/components/Logo';

const Header = () => {
  return (
    <Row justify="space-between" style={{ height: '100%' }}>
      <Col lg={{ span: 6 }} xs={{ span: 3 }}>
        <Logo />
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 21 }} style={{ height: '100%' }}>
        <RightPanel />
      </Col>
    </Row>
  );
};

export default Header;
