import { API_ENDPOINT } from '@constants/configs';
import CustomAxiosInstance from '@utils/axios';
import axios from 'axios';

export const apiInstance = new CustomAxiosInstance(API_ENDPOINT);

export const rawApiInstance = axios.create({
  baseURL: API_ENDPOINT,
  validateStatus: () => true,
});

export default apiInstance;
