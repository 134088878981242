import ROUTE_PATH from '@constants/routes';
import { useAppSelector } from '@redux/hooks';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Right = () => {
  const { t } = useTranslation('Common');
  const authorization = useAppSelector((state) => state.authorization);

  return (
    <>
      <a href="/#header-content">
        <Typography.Text className="glc-text text">
          ShinYuriCampの魅力
        </Typography.Text>
      </a>
      {!authorization && (
        <Typography.Text className="glc-text text">
          <Link to={ROUTE_PATH.SIGN_IN}>{t('Navigation.Login')}</Link>
        </Typography.Text>
      )}
      <Typography.Text className="glc-text text">
        <Link to={ROUTE_PATH.CONTACT}>{t('Navigation.Contact')}</Link>
      </Typography.Text>
    </>
  );
};

export default Right;
