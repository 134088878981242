import apiInstance from '@apis/index';

export const getChatbotConfig = async (): Promise<ChatbotConfig> => {
  const response = await apiInstance.get('chatbot-config');
  return response.data;
};

export interface ChatbotConfig {
  botId: number;
  botUrl: string;
}
